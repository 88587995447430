import React, { useEffect } from "react";

import "./PaymentForm.scss";

export default function PaymentFormServiceBot() {
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    window.servicebotSettings = {
      "billing_page_id": "dOe22ZsMuIhwKJ3nCDaD"
};
  (function () { var s = document.createElement('script'); s.src = 'https://js.servicebot.io/embeds/servicebot-billing-settings-embed.js'; s.async = true; s.type = 'text/javascript'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();    
  }, []);
 

  return (
    <div className="payment-form container pb-5" style={{paddingTop: '150px'}}>
    <div className="row ">
        <div className="col-md-12 ">
        <h2 className="section-title text-center">Helm Detector Subscription</h2>
          
        </div>
    </div>
    <div id="servicebot-subscription-portal"></div>

    
    
    </div>
  );
}
