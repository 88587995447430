/*eslint-disable*/
import React from "react";

function HowItWorksPart2() {
  React.useEffect(() => {});

  return (
    <section
      className="section-grey section-top-border section-bottom-border"
      id="features"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center padding-bottom-10">
            <h2 className="section-title">Benefits</h2>

            <p className="section-subtitle">
              The world of public health and safety is constantly evolving. 
              <br />
              Stay ahead of the curve with Helm Detector and Patent Pending process. 
              
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="0.25s"
              style={{visibility: "visible", animationDelay: "0.25s", animationName: "fadeIn"}}
            >
              <i className="pe-7s-config"></i>

              <h4>Personalized Dashboard</h4>

              <p>
                Live view of all entrances to your business
                where Helm Detectors are installed.
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="0.5s"
              style={{visibility: "visible", animationDelay: "0.25s", animationName: "fadeIn"}}
            >
              <i className="pe-7s-tools"></i>

              <h4>User Friendly</h4>

              <p>
                Intuitive, easy-to-use software so you can feel comfortable
                with your system.
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="0.75s"
              style={{visibility: "visible", animationDelay: "0.25s", animationName: "fadeIn"}}
            >
              <i className="pe-7s-bell"></i>

              <h4>Smart Notifications</h4>

              <p>
                As long as you have your phone, you have access to any 
                important notifications sent by your system.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="1s"
              style={{visibility: "visible", animationDelay: "0.25s", animationName: "fadeIn"}}
            >
              <i className="pe-7s-piggy"></i>

              <h4>Low Cost</h4>

              <p>
                Once your cameras are professionally installed, 
                you only pay a monthly subscription for software updates and monitoring.
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="1.25s"
              style={{visibility: "visible", animationDelay: "0.25s", animationName: "fadeIn"}}
            >
              <i className="pe-7s-diamond"></i>

              <h4>Valuable Insights</h4>

              <p>
                The world has entered a new normal, we all must do our part
                to flatten the curve. Having the right information can save lives.
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="1.5s"
              style={{visibility: "visible", animationDelay: "0.25s", animationName: "fadeIn"}}
            >
              <i className="pe-7s-mail-open-file"></i>

              <h4>Support 24/7</h4>

              <p>
                Encounter a problem? Perfect. We are here to help. 
                Day, night, or quarantine we can provide support.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowItWorksPart2;
