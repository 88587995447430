/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Button, Modal } from "reactstrap";
import YouTube from "react-youtube";
// core components
import "./Header.scss";


function Header() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  var modalOpen = false;
  if (location.hash === '#video-open') { modalOpen = true}
  const [modalLarge, setModalLarge] = React.useState(modalOpen);
  const opts = {
    width: "100%",
    height: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };


  return (
    <>
      <div
        className="page-header clear-filter"
        filter-color="black"
        style={{ display: "block" }}
      >
        <div className="page-header-image" ref={pageHeader}>
          <video
            width="100%"
            id="background-video"
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={"/thermal-final.mp4"} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <Container>
          <div className="content-center brand">
            <h1 className="h1-seo" style={{ color: "white", fontWeight: 500 }}>
              Elevated Body Temperature & Face Mask Detection with AI.
            </h1>
            <h3 style={{ color: "white" }}>
              Introducing Lookout Labs Helm Detector
            </h3>
            <Button
              color="white"
              type="button"
              onClick={() => setModalLarge(true)}
            >
              Watch our Demo
            </Button>
            <Modal toggle={() => setModalLarge(false)} isOpen={modalLarge}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLiveLabel">
                  Modal title
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  type="button"
                  onClick={() => setModalLarge(false)}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body" style={{ height: "100%" }}>
                {/* <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ZqoeAlTaSW4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                <YouTube videoId="ZqoeAlTaSW4" opts={opts} />
              </div>
              <div className="modal-footer">
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => setModalLarge(false)}
                >
                  Close
                </Button>
              </div>
            </Modal>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Header;
