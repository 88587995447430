/*eslint-disable*/
import React from "react";

function FunFacts() {
  React.useEffect(() => {});

  return (
    <section className="section-blue medium-paddings">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center padding-top-10 padding-bottom-20">
            <h3 className="section-title white-text">Fun Facts About Nexus</h3>

            <p className="section-subtitle white">
              Here are a few facts about our awesome software.
            </p>
          </div>

          <div
            className="fun-facts-box text-center wow fadeIn"
            data-wow-delay="0.15s"
          >
            <i className="pe-7s-diamond"></i>

            <p className="fun-facts-title">
              <span className="facts-numbers">1050+</span>
              <br />
              Projects Completed
            </p>
          </div>

          <div
            className="fun-facts-box text-center wow fadeIn"
            data-wow-delay="0.3s"
          >
            <i className="pe-7s-umbrella"></i>

            <p className="fun-facts-title">
              <span className="facts-numbers">217k</span>
              <br />
              Happy Clients
            </p>
          </div>

          <div
            className="fun-facts-box text-center wow fadeIn"
            data-wow-delay="0.45s"
          >
            <i className="pe-7s-cup"></i>

            <p className="fun-facts-title">
              <span className="facts-numbers">1210</span>
              <br />
              Design Awards
            </p>
          </div>

          <div
            className="fun-facts-box text-center wow fadeIn"
            data-wow-delay="0.6s"
          >
            <i className="pe-7s-coffee"></i>

            <p className="fun-facts-title">
              <span className="facts-numbers">2137</span>
              <br />
              Cups Of Coffee
            </p>
          </div>

          <div
            className="fun-facts-box text-center wow fadeIn"
            data-wow-delay="0.75s"
          >
            <i className="pe-7s-comment"></i>

            <p className="fun-facts-title">
              <span className="facts-numbers">24/7</span>
              <br />
              Fast Support
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FunFacts;
