/*eslint-disable*/
import React, { Component } from "react";

class Pitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultMessage: "none"
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state)
    };

    fetch(
      "/get-started", requestOptions
    )
      .then(response => response.json())
      .then(data => this.setState({ resultMessage: "block" }));
  }

  render() {
    return (
      <section className="home-section" id="home">
        <div className="container">
          <div className="row">
          <div className="col-md-5 order-md-2">
              <img
                alt="product shot"
                className="first-shot"
                src={require("assets/img/product-shot.png")}
              ></img>
            </div>
            <div className="col-md-7 padding-top-40 order-md-1">
              <h2 className="section-title">
                Make Safety and Well-Being Your #1 Priority
              </h2>
              <p></p>

              <div className="newsletter_form_box">
                <h3 style={{ display: this.state.resultMessage }}>Thank You For Your Request. Someone Will Contact You Shortly</h3>
                <form
                  id="newsletter-form"
                  className="newsletter-form"
                  onSubmit={this.handleSubmit}
                  style={{ display: this.state.resultMessage == "none" ? "block" : "none" }}
                >
                  <input
                    id="email_newsletter"
                    type="email"
                    name="email"
                    placeholder="Enter Your Email Address"
                    required={true}
                    value={this.state.email}
                    onChange={this.handleChange}
                  />

                  <input
                    type="submit"
                    value="GET STARTED!"
                    id="submit-button-newsletter"
                  />
                </form>
              </div>

              <p></p>

              <p>
                <strong>The world is changing every day</strong>, and rapidly so
                with the onset of the Covid-19 outbreak. As a business owner,
                this presents an even bigger challenge: how do you serve your
                customers but also keep them safe? How do you keep the
                confidence of your employees, who come to work because they
                trust you to maintain a healthy environment?
              </p>

              <p>
                Meet the Helm Detector. Leveraging the power of reliable and
                high-speed elevated temperature detection is the best way to
                assure your employees and customers that you are taking the
                necessary steps to{" "}
                <strong>slow the spread of infection.</strong>
              </p>

              <p>
                <strong>
                  Control CoronaVirus--Don't allow it to control you!
                </strong>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Pitch;
