/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function Footers() {
  return (
    <footer className="footer bg-dark" style={{overflow:'inherit'}}>
      <Container>
      <div className="col-md-12 text-center">
      <h6 style={{color:'#fff'}}>Get In Touch</h6>
        <a
          href="mailto:hello@lookoutlaboratories.com?subject=Hello"
          style={{ color: "#FFFFFF" }}
        >
          <i className="fa fa-envelope"></i> hello@lookoutlaboratories.com
        </a>
      </div>
      <div className="col-md-12">
        <div id="copyright" style={{ color: "#FFFFFF", 'textAlign': 'center' }}>
          © {new Date().getFullYear()} | Lookout lookoutlaboratories | 
            <a
          href="/tos"
          style={{ color: "#FFFFFF" }}
        >
          Terms of Service
        </a>
        </div>
      </div>
        
      </Container>
    </footer>
  );
}

export default Footers;
