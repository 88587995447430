import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss";

// template styles
import "./assets/css/style.css";
import "./assets/css/animate.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/style-magnific-popup.css";


// pages for this kit
import Index from "./views/Index.js";
import Instructions from "./views/Instructions.js";
import Payments from "./views/Payments.js";
import Tos from "./views/Tos";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
        <Route exact path="/" render={props => <Index {...props} />} />
        <Route
          exact
          path="/instructions"
          component={Instructions}
        />
        <Route
          exact
          path="/payments"
          component={Payments}
        />
        <Route
          exact
          path="/tos"
          component={Tos}
        />

    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
