import React from "react";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
import Footers from "components/Footers";
import EULA  from 'components/EULA';





// sections for this page

function Tos() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <div>
      <MainNavbar />
      <EULA/>
      <Footers />
    </div>
  );
}

export default Tos;
