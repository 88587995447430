/*eslint-disable*/
import React from "react";

function Benefits() {
  React.useEffect(() => {});

  return (
    <section className="section-white section-top-border">
      <h2 className="section-title text-center">Why Should You Trust Helm Detector?</h2>
      <br/>
      <div className="container">
        
        <div className="row">
          <div
            className="col-md-6 responsive-bottom-margins wow slideInLeft"
            data-wow-delay="0.25s"
            style={{
              visibility: "visible",
              animationDelay: "0.25s",
              animationName: "slideInLeft"
            }}
          >
             <img
              alt="product shot"
              height="450px"
              src={require("assets/img/demo shot-2.jpg")}
            ></img>
          </div>

          <div className="col-md-6 padding-top-20">
            <div className="features-second">
              <div className="dropcaps-circle">
                <i className="pe-7s-signal"></i>
              </div>

              <h4 className="margin-bottom-5">Contactless</h4>

              <p>
                Set up your Helm at any entrance to scan employees and customers from a safe distance. 
              </p>
            </div>

            <div className="features-second">
              <div className="dropcaps-circle">
                <i className="pe-7s-rocket"></i>
              </div>

              <h4 className="margin-bottom-5">Effortless</h4>

              <p>
                Cameras will be professionally installed for immediate use, keeping your business and your peace of mind going.
              </p>
            </div>

            <div className="features-second">
              <div className="dropcaps-circle">
              <i className="pe-7s-smile"></i>
              </div>

              <h4 className="margin-bottom-5">Harmless</h4>

              <p>
                Instantly receive SMS notification when an elevated temperature or missing face mask is detected.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Benefits;
