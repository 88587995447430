import React from "react";
// import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  // UncontrolledTooltip
} from "reactstrap";
import { ReactComponent as LookoutLogo } from "assets/img/lookout-labs-logo-01.svg";
import "./Navbars.scss";

function MainNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("bg-dark");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"bg-dark fixed-top"} color="dark" expand="lg">
        <Container>
          <div
            className="navbar-translate"
            style={{ paddingLeft: 20, paddingRight: 20 }}
          >
            <NavbarBrand href="/" target="_blank" id="navbar-brand">
              <LookoutLogo height={"40px"} />
            </NavbarBrand>
          </div>
          <Nav navbar>
            {/* <NavItem>
              <NavLink href="/payments">
                <i className="now-ui-icons shopping_credit-card"></i>Make A Payment
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink href="/instructions">
                <i className="now-ui-icons ui-1_settings-gear-63"></i>Setup Instructions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="mailto:hello@lookoutlaboratories.com?subject=Hello">
                <i className="now-ui-icons ui-1_email-85"></i> Get in Touch
              </NavLink>
            </NavItem>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default MainNavbar;
