/*eslint-disable*/
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { NONAME } from "dns";
// import "./Upload.css";
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from "reactstrap";
import { FormGroup } from "semantic-ui-react";
import { Form } from 'semantic-ui-react';
import "./Contact.scss"

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultMessage: "none"
    };

    this.handleChange = this.handleChange.bind(this);
    this.onReCaptcha = this.onReCaptcha.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onReCaptcha(value) {
    this.setState({ captcha: value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state)
    };

    fetch(("/send-message?g-recaptcha-response=" + this.state.captcha), requestOptions)
      .then(response => response.json())
      .then(data => this.setState({resultMessage: "block"}));

  }

  render() {
    return (
      <section className="section-grey section-top-border" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-12 form-col">
              <h4 className="text-center">Get an Estimate</h4>

              <h5 style={{ display: this.state.resultMessage, textAlign: 'center' }}>
                Thank you for your request. Someone will follow up with you
                shortly.
              </h5>

              <form
                id="contact-form"
                className="contact"
                onSubmit={this.handleSubmit}
                style={{ display: this.state.resultMessage == "none" ? "block" : "none" }}
              >
                <InputGroup
                className="input-lg">
                <InputGroupAddon addonType="prepend">
                
                  <InputGroupText>
                    <i className="now-ui-icons users_circle-08"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  name="name"
                  required={true}
                  name="name"
                  placeholder="Full Name*"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </InputGroup>
              <InputGroup className="input-lg">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="now-ui-icons ui-1_email-85"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="email"
                  name="email"
                  required={true}
                  placeholder="Email Address*"
                  value={this.state.email}
                  onChange={this.handleChange}
                  />
              </InputGroup>
              <div className="textarea-container">
                       
              <Input
                  className="form-control"
                  rows="3"
                  type="textarea"
                  name="message"
                  placeholder="Your Message..."
                  value={this.state.message}
                  onChange={this.handleChange}
                  /></div>
                <div style={{display:'flex',  'alignItems': 'center', 'justifyContent': 'center'}}>
                

                <ReCAPTCHA
                  sitekey={"6Ldv2_UUAAAAAPzorV9VBjJ7_lP-mURYmfUGR85O"}
                  onChange={this.onReCaptcha}
                />
                </div>
                <div className="input-lg input-group" style={{ paddingTop: '2rem'}}>


                <input
                  value="Submit"
                  id="submit-button"
                  className="contact-submit"
                  type="submit"
                  style={{'width': '70%', margin: '0 auto'}}

                />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
