import React from "react";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
// import Header from "components/Header";
import InstructionSteps from "components/InstructionSteps";
// import Benefits from "components/Benefits";
// import HowItWorks from "components/HowItWorks";
// import HowItWorksPart2 from "components/HowItWorksPart2";
// import FunFacts from "components/FunFacts";
// import Contact from "components/Contact";
import Footers from "components/Footers"
// import WhatWeOffer from "components/WhatWeOffer";
// import Privacy from "components/Privacy";
import MoreInformation from 'components/MoreInformation';
import ExtraInstructions from 'components/ExtraInstructions';



// sections for this page

function Instructions() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <div>
      <MainNavbar />
      <MoreInformation />
      <InstructionSteps />
      {/* <ExtraInstructions /> */}
      <Footers />
    </div>
  );
}

export default Instructions;
