/*eslint-disable*/
import React from "react";

import {ReactComponent as Device} from 'assets/img/device.svg';

import {ReactComponent as SMS} from 'assets/img/sms.svg';

import {ReactComponent as Scan} from 'assets/img/scan.svg';

import './HowItWorks.scss';

function HowItWorks() {
  React.useEffect(() => {});

  return (
    <section
      className="section-grey section-top-border section-bottom-border"
      id="features"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center padding-bottom-10">
            <h2 className="section-title">How Does It Work?</h2>

            <p className="section-subtitle">
              No active monitoring required! Just set and forget,
              let the software do the work.{" "}
              <strong>If an elevated body temperature or missing face mask is detected</strong>, an automated message gets
              sent to your phone immediately. This gives you the freedom and the
              control to make informed decisions about your business.{" "}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="0.25s"
              style={{
                visibility: "visible",
                animationDelay: "0.25s",
                animationName: "fadeIn"
              }}
            >
            <div className="hiw-icon">
               <Device />
            </div>

              <h4>1. Contact Free Monitoring</h4>

              <p>
              Automatically track customers as they enter your business with the cameras on the Helm Detector
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationDelay: "0.25s",
                animationName: "fadeIn"
              }}
            >
             <div className="hiw-icon">
             <Scan 
            />
            </div>
              

              <h4>2. Analyze the Data</h4>

              <p>
              Artificial Intelligence immediately and non-invasively checks each customer for signs of an elevated body temperature or presence of a face mask.
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="0.75s"
              style={{
                visibility: "visible",
                animationDelay: "0.25s",
                animationName: "fadeIn"
              }}
            >
              <div className="hiw-icon">
             <SMS 
            />
            </div>

              <h4>3. Get Notified</h4>

              <p>
              Automatic text message alerts give your team the information they need to take action right away
              </p>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

export default HowItWorks;
