/*eslint-disable*/
import { ReactComponent as Device } from 'assets/img/device.svg';
import { ReactComponent as Scan } from 'assets/img/scan.svg';
import { ReactComponent as SMS } from 'assets/img/sms.svg';
import React from "react";
import './MoreInformation.scss';





function MoreInformation() {
  React.useEffect(() => {});

  return (
    <section
      className="section-grey section-top-border section-bottom-border"
      id="features"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center padding-bottom-10 py-5">
            <h2 className="section-title">Helm Detector Setup Instructions</h2>

            <p className="section-subtitle">
             The Helm Detector is a passive Body Temperature Measurement Device, once setup and calibrated by the Lookout Team for your location it will keep working as long as it is powered up and connected to the internet.

              
            </p>
          </div>
        </div>

        <div className="row pt-4">
          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="0.25s"
              style={{
                visibility: "visible",
                animationDelay: "0.25s",
                animationName: "fadeIn"
              }}
            >

              <h4>Recommended Mount Height</h4>

              <h6>
              5.5 - 6.5 Feet Above Ground
              </h6>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationDelay: "0.25s",
                animationName: "fadeIn"
              }}
            >
              

              <h4>Max Recommended Detection Distance</h4>

              <h6>
              8 Feet
              </h6>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="feature-box wow fadeIn"
              data-wow-delay="0.75s"
              style={{
                visibility: "visible",
                animationDelay: "0.25s",
                animationName: "fadeIn"
              }}
            >

              <h4>Max Rated Ambient Temperature</h4>

              <h6>
              80˚F
              </h6>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

export default MoreInformation;
