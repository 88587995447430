/*eslint-disable*/
import React, { Component } from "react";
import './InstructionSteps.scss';

class InstructionSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultMessage: "none",
    };
  }

  render() {
    return (
      <section className="home-section instructions" id="home">
        <div className="container">
          <div className="row pt-5 pb-5">
            <div className="col-md-5">
              <img
                alt="product shot"
                className="instruction-img"
                src={require("assets/img/boxing-small.png")}
              ></img>
            </div>
            <div className="col-md-7 padding-top-40 padding-bottom-40">
              <h2 className="section-title">Step One</h2>
              <p>
                Open your Helm Detector Box, you will have a Helm Detector,
                USB-C Power Adapter, Ethernet Cable, and Mount provided by Lookout Labs. (please note you may need a longer ethernet cable)
              </p>
            </div>
          </div>

          <div className="row pt-5 pb-5">
            <div className="col-md-5">
              <img
                alt="product shot"
                className="instruction-img"
                src={require("assets/img/stand.png")}
              ></img>
            </div>
            <div className="col-md-7 padding-top-40 padding-left-40">
              <h2 className="section-title">Step Two</h2>
              <p>
                Assemble the Mount
                <ul>
                  <li>
                    Screw threaded end of the arm into circular black base.
                  </li>
                  <li>
                    Place Assembeld Mount on surface where detections will
                    occur.
                  </li>
                  <li>
                    Unscrew the thumb screw on the arm to adjust it up and down
                    in the base to a height of 5.5-6.5 feet above the ground.
                  </li>
                  <li>Tighten Thumb Screws.</li>
                </ul>
                <p>You are now ready to attach the Detector.</p>
              </p>
            </div>
          </div>

          <div className="row pt-5 pb-5">
            <div className="col-md-5">
              <img
                alt="product shot"
                className="instruction-img"
                src={require("assets/img/mount-connected-small.png")}
              ></img>
            </div>
            <div className="col-md-7 padding-top-40 padding-left-40">
              <h2 className="section-title">Step Three</h2>
              <p>
                Connect the Helm Detector to the Mount by using the 1/4 Inch
                Bolt in the base of the detector and connect it to the 1/4 inch
                screw on the arm. To tigehten twist the thumb screw so the
                rubberized padding is firmly touching the base of the detector. Be sure not to overighten as it could damage the plastic.
              </p>
            </div>
          </div>

          <div className="row pt-5 pb-5">
            <div className="col-md-5">
              <img
                alt="product shot"
                className="instruction-img"
                src={require("assets/img/height-small.png")}
              ></img>
            </div>
            <div className="col-md-7 padding-top-40 padding-left-40">
              <h2 className="section-title">Step Four</h2>
              <p>
                Position the Helm Detector at head height, approximately 5.5 -
                6.5 feet off the ground. The Detector works best where the
                front of the subjects head will face it.
              </p>
            </div>
          </div>

          <div className="row pt-5 pb-5">
            <div className="col-md-5">
              <img
                alt="product shot"
                className="instruction-img"
                src={require("assets/img/usb-connect-small.png")}
              ></img>
            </div>
            <div className="col-md-7 padding-top-40 padding-left-40">
              <h2 className="section-title">Step Five</h2>
              <p>
                Connect the USB-C Power Adapter to the Wall and port on the top
                left of the Helm Detector.
              </p>
            </div>
          </div>
          <div className="row pt-5 pb-5">
            <div className="col-md-5">
              <img
                alt="product shot"
                className="instruction-img"
                src={require("assets/img/ethernet-connect-small.png")}
              ></img>
            </div>
            <div className="col-md-7 padding-top-40 padding-left-40">
              <h2 className="section-title">Step Six</h2>
              <p>
                Connect the Ethernet Cable to your network using either the
                provided cable or another Ethernet Cable.
              </p>
            </div>
          </div>
          <div className="row pt-5 pb-5">
            <div className="col-md-5">
              <img
                alt="product shot"
                className="instruction-img"
                src={require("assets/img/wire-wrap-small.png")}
              ></img>
            </div>
            <div className="col-md-7 padding-top-40 padding-left-40">
              <h2 className="section-title">Step Seven</h2>
              <p>
                Wrap the wires down the base. Be sure to either tape these
                cables down or use tie downs to avoid tripping on them.
              </p>
            </div>
          </div>

          <div className="row pt-5 pb-5">
            <div className="col-md-5">
              <img
                alt="product shot"
                className="instruction-img"
                src={require("assets/img/click-small.png")}
              ></img>
            </div>
            <div className="col-md-7 padding-top-40 padding-left-40">
              <h2 className="section-title">Step Eight</h2>
              <p>
                With the Ethernet and Power Cable connected the Helm Detector
                click the button on the USB-C cable to illuminate the Red LED.
                The Detector will now power up, please note it takes
                approximately 10 minutes for the thermal detector in the Helm
                Detector to heat up. The Detector will not make detection until it heats up.
              </p>
            </div>
          </div>

          {/* <div className="row pt-5 pb-5">
            <div className="col-md-5">
              <img
                alt="product shot"
                className="instruction-img"
                src={require("assets/img/product-shot.png")}
              ></img>
            </div>
            <div className="col-md-7 padding-top-40 padding-left-40">
              <h2 className="section-title">Step Seven</h2>
              <p>
                Once the Helm Detector is operational, double check the
                positiong of the detector from a Computer or Mobile Device that is
                on the same wired/wifi network as your detector by visiting
                http://<b>HELM-NAME</b>.local:5000 (Please note your{" "}
                <b>HELM-NAME</b> will be inside of your instruction manual along
                with the default username and login).
              </p>
            </div>
          </div> */}
          <div className="row pt-5 pb-5">
            <div className="col-md-5">
              <img
                alt="product shot"
                className="instruction-img"
                src={require("assets/img/product-shot.png")}
              ></img>
            </div>
            <div className="col-md-7 padding-top-40 padding-left-40">
              <h2 className="section-title">Step Nine</h2>
              <p>
                Once the Helm Detector camera is pointed where you want it,
                please reach out to Lookout Labs at{" "}
                <a href="mailto:hello@lookoutlaboratories.com?subject=Calibration">
                  hello@lookoutlaboraties.com
                </a>{" "}
                so that we can confirm your setup, our technicians will let you
                know when your detector is ready to use and calibrated for its new
                location. Please do not shutdown the Detector during calibration. Once the calibration is completed you will be ready to
                start Detecting Elevated Body Temperatures and Recieving
                Notifactions.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default InstructionSteps;
