/*eslint-disable*/
import React from "react";
import "./EULA.scss";

function EULA() {
  React.useEffect(() => {});

  return (
    <div className="container">
      <div className="col-md-12 overflow-auto eula-container">
        <h2 className="section-title">System Purchase and Evaluation Agreement</h2>
<p>IMPORTANT: BY USING YOUR HELM DETECTOR (&ldquo;Hardware&rdquo;) AND SOFTWARE LICENSES (&ldquo;Software&rdquo;), YOU ARE AGREEING TO BE BOUND BY THE FOLLOWING TERMS:</p>
<p>Lookout Labs, LLC (&ldquo;<strong>Lookout</strong>&rdquo;) and Customer (each a &ldquo;<strong>Party</strong>&rdquo; and collectively, the &ldquo;<strong>Parties</strong>&rdquo;) are entering into this System Purchase and Evaluation Agreement (this &ldquo;<strong>Agreement</strong>&rdquo;) as of the date both Parties have signed below ("<strong>Effective Date</strong>"). The terms and conditions of this Agreement will govern Customer's purchase and Lookout&rsquo;s provision of the hardware, software licenses, and services set forth below.&nbsp;</p>
<p>In consideration of the mutual covenants, terms and conditions set forth herein, and for other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, the Parties agree as follows:</p>
<ul>
<li><strong>Orders</strong>.
<ol>
<li>Customer agrees to purchase hardware devices ("<strong>Hardware"</strong>), software licenses ("<strong>Software</strong>"), messaging services (&ldquo;<strong>Notifications</strong>&rdquo;) and professional services (collectively with Notifications, "<strong>Services</strong>", and collectively with Hardware and Software, the "<strong>Products</strong>") that are identified in Exhibit A of this Agreement in the amounts and quantities identified in that Exhibit. If Customer desires to purchase additional Products from Lookout during the Term of this Agreement, such purchases will be governed by this Agreement. Purchases of Software and Services are on a subscription basis that automatically renews for additional terms as set forth in Exhibit A. As a result Customer&rsquo;s payment card will be automatically charged for each renewal term unless Customer terminates this Agreement in accordance with Section 10 below.&nbsp;</li>
<li>Each Product may only be used and installed at one physical location owned and controlled by Customer (&ldquo;<strong>Location</strong>&rdquo;).</li>
<li>If Customer is required to issue a purchase order ("<strong>Purchase Order</strong>") to authorize the purchase of Products from Lookout, Customer will issue such Purchase Order promptly and Lookout has no obligation to provide or deliver Products unless and until Customer delivers such Purchase Order. By issuing a Purchase Order, Customer makes an offer to purchase the Products pursuant to the terms and conditions of this Agreement, which offer may be accepted or rejected by Lookout. Each Purchase Order will contain the list of Products to be purchased, the quantity each Product, the requested delivery date, the unit price for each Product, the billing address, delivery location, and if different the Location at which the Product will be installed and used. In accordance with Section 28 below, any other terms or conditions printed on, attached to, or referenced by the Purchase Order are void and have no effect.</li>
</ol>
</li>
<li><strong>Product Development.</strong>
<ol>
<li>Customer understands and agrees that the Products are in the development stage and have not been tested for use in any particular environment or infrastructure. As a result, Lookout makes no representation, warranty, or guarantee as to the fitness of the Location for, accuracy of, or any features or functionality of the Products.&nbsp;</li>
<li>Customer agrees that it may not rely solely on any Notifications or other output of the Products and must directly confirm whether any individual meets Customer&rsquo;s policies and parameters for remaining on or in Customer&rsquo;s Location. In the event Customer receives any Notification regarding an individual from the Products, Customer must separately and physically verify the temperature of the individual and report back to Lookout whether the physical temperature matched that of the Product Notification for auditing and verification of the Products. In no event may Customer share with or disclose to Lookout any identifiable information of any individual consumer. Customer is solely responsible for any decision Customer makes regarding the removal of any individual present on or in the Location and all other acts and omissions of Customer or its personnel.&nbsp;</li>
<li>Customer acknowledges and agrees that the Products do not provide medical advice, nor should use of these Products be expressly relied upon in the treatment or health analysis of any individual or used as a replacement for good medical judgment. Lookout does not guarantee the results of the Products, nor does it guarantee or warrant that the Products, even when used as intended in accordance with Documentation, will slow or stop to spread of any disease or illness, including without limitation COVID-19 or any other virus. Customer must follow all applicable laws, rules, regulations, codes, and ordinances that apply to its business and to its use of the Products. It is Customer's sole responsibility to help ensure the safety of employees, agents, and customers. Lookout does not represent, warrant or guarantee that use of the Products complies with any data privacy or security law or regulation or allows Customer to be compliant with any other law or regulation, including without limitation Health Insurance Portability and Accountability Act (HIPAA) and its subsequent legislation and regulations (including without limitation HITECH).&nbsp;</li>
<li>The Hardware must remain connected to the Internet to facilitate Lookout&rsquo;s remote monitoring and assessment of the Hardware and Software. Lookout may gather and otherwise use any and all data regarding the function and operation of any Products, including any Performance Data as set forth below, in order to further develop, modify and otherwise improve the same or any other product.&nbsp;</li>
</ol>
</li>
<li><strong>Hardware</strong>.
<ol>
<li>Lookout will use commercially reasonable efforts to deliver Products as ordered. Products will be delivered to the address provided by Customer on the Purchase Order unless the Parties agree to otherwise. Customer is responsible for confirming the accuracy of all addresses, and Lookout has no responsibility for shipment errors or delays caused by address errors. If the delivery address requested by Customer is other than, the delivery location indicated on the applicable Purchase Order, Customer is solely responsible for transferring Products to that location if necessary.</li>
<li>Delivery of Hardware is FOB Origin. Risk of loss passes to Customer upon Lookout's transfer of the Hardware to the designated carrier for shipment. Lookout will not be liable for any delays, loss, or damage in transit. All freight charges are borne by Customer and will be billed on invoice(s). Customer is responsible for proper storage and handling of Products after receipt. Products that are unable to be installed due to damage after risk of loss has pass to Customer will be replaced solely at Customer&rsquo;s cost. Title to the Hardware passes solely upon Lookout&rsquo;s receipt of payment in full for the particular Hardware unit. Title to the Hardware unit does not transfer any title, right or interest in or to the Software, Documentation or any Lookout Property beyond the limited licenses granted in this Agreement.</li>
<li>Customer is responsible for all loading costs, providing equipment and labor reasonably suited for receipt of the Products at the designated point of delivery, and unloading and releasing all transportation equipment promptly.</li>
<li>Products are deemed accepted within five (5) days of delivery to Customer&rsquo;s designated delivery address. Customer must notify Lookout of any shipment errors within such five (5) day period. Missing or damaged shipments should be reported to the third party shipping carrier.</li>
<li>Products may not be returned or exchanged without prior written approval from Lookout in Lookout&rsquo;s discretion. Customer is responsible for repackaging and shipping all Product returns at Customer&rsquo;s cost and in accordance with Lookout&rsquo;s directions. Products will not be approved for returns or exchanges beyond the 7th day after delivery. Product returns or exchanges may be subject to restocking fees. Lookout has no obligation to accept returns that were not preapproved, or are not shipped or packaged for Lookout in accordance with Lookout&rsquo;s instructions.</li>
<li>All Hardware must be and remain connected to the Internet at the Location and Customer must ensure the same. Customer cannot receive Services in the event the Hardware is not connected to the Internet.&nbsp;</li>
<li>Customer is solely responsible for installing Products at its Location in accordance with Lookout's installation instructions, if any. Customer will provide Lookout with details of the Location specifications and location details for Customer to use in its analysis of Performance Data as may be requested by Lookout. Customer must notify Lookout of and provide Lookout with information about where Customer will have all A/C vents, heat sources, networking, cabling, and telecommunications connections in place along with any other third party hardware and software required for the installation of the Products and performance of the Services. Lookout will be available for remote support Services during installation, but Lookout is not responsible for any performance delays or Product errors caused by a failure of Customer to follow Lookout&rsquo;s instructions provided as part of such support.&nbsp;</li>
</ol>
</li>
<li><strong>Software</strong>.
<ol>
<li><u>License</u>. During the Term (as defined below), conditioned on Customer&rsquo;s compliance with the terms and conditions of this Agreement, Lookout grants to Customer a limited, nonexclusive, nonassignable, nontransferable license to (a) use one copy of the Software as preinstalled on the applicable Hardware for Customer&rsquo;s internal business purposes and (b) make a reasonable number of copies of any manuals, written guides, specifications or other documentation provided with the Software ("<strong>Documentation</strong>") solely for use by Customer&rsquo;s employees as necessary for use of the Products and training on the operation of such Products. No other license or right is deemed granted or implied under this Agreement.</li>
<li><u>License Restrictions</u>.
<ol>
<li>Customer may not disclose the Software or any part thereof except to those of its employees who require such disclosure to perform duties in the scope of their employment for Customer. Customer must prevent unauthorized disclosure or copying of all Software and Documentation. Customer will adopt and enforce such internal policies, procedures, and monitoring mechanisms as are necessary to ensure the Software and Documentation is used only in accordance with the terms of this Agreement.</li>
<li>Customer may not assign, sublicense, lease, encumber, or otherwise transfer or attempt to transfer the Software or Documentation or any portion thereof or otherwise permit any third party to use or have access to the Software or Documentation whether by timesharing, networking, or any other means. Customer may not, and may not permit any third party to, otherwise reproduce, install, or copy the Software or Documentation in whole or in part.</li>
</ol>
</li>
</ol>
</li>
</ul>
<ul>
<li>Customer may not, and may not permit any third party to, modify, distribute, display, translate, reverse engineer, decompile, or disassemble the Hardware, Software or Documentation or attempt to derive any source code from the same.</li>
</ul>
<ol>
<li>Customer may not, and may not permit any third party to, remove any copyright, trademark, patent, or other proprietary notices from the Software, Documentation, or any portion thereof.</li>
<li>Customer may only use Software with the Hardware on which it is installed by or on behalf of Lookout pursuant to this Agreement. Software and Hardware may not be moved from or installed in any facility other than the Location without Lookout&rsquo;s express prior written consent. Any violation of this provision by Customer or any of its employees, contractors, or personnel constitutes a waiver of any and all warranties provided herein.</li>
<li>In addition to any other rights and remedies that Lookout may have at law or in equity, in the event of Customer&rsquo;s breach or threatened breach of any of the provisions of this Section, Lookout has the right to obtain a permanent or preliminary injunction preventing such breach or threatened breach in any court or jurisdiction without the need to post bond or meet a similar requirement.</li>
</ol>
<ul>
<li><strong>Services</strong>.
<ol>
<li><u>Text Messages</u>. The Software is designed to send data to Lookout regarding the body temperature of individuals that the Hardware detects. If the Hardware detects a temperature above a certain threshold selected by Customer, Lookout sends Customer Notifications via text message to a device owned and controlled by Customer. Customer agrees to receive such Notifications via SMS text message. Such Notifications may result in data charges pursuant to Customer&rsquo;s telecommunications carrier. Customer must ensure all telephone numbers identified to Lookout for receipt of Notifications are business numbers owned and controlled by Customer. Customer must ensure that any telephone number is not an individual employee&rsquo;s number in such employee&rsquo;s role as a consumer. Notwithstanding the foregoing, Customer expressly consents, on behalf of itself and its employees, to the receipt of SMS text messages from automated dialing systems at the cell phone numbers provided by Customer, in order to receive the Notifications as contemplated by the Products.&nbsp;</li>
<li><u>Development</u>. In the event Customer wishes custom development for any Hardware or Software the Parties will enter into a separate development agreement contemplating the fees and charges for such development.&nbsp;</li>
<li><u>Maintenance Services</u>.
<ol>
<li>The monthly subscription fees set forth in Exhibit A include standard support and maintenance Services, if and as provided by Lookout.</li>
<li>Support and maintenance Services consist of access to and delivery of any bug fixes, updates, patches and similar modifications to the Software that Lookout that makes available to all its other customers (&ldquo;<strong>Updates</strong>&rdquo;). Support and maintenance Services do not include upgrades, enhancements, new features or functionality that are separately licensed by Lookout for an additional fee (&ldquo;<strong>Upgrades</strong>&rdquo;). Any Updates or Upgrades provided to or purchased by Customer become part of the &ldquo;Software&rdquo; as licensed to Customer pursuant to this Agreement. Any Updates or Upgrades purchased by Customer will be automatically pushed to the Hardware by Lookout when such Updates and Upgrades are ready, which may be with or without notice to Customer.&nbsp;</li>
</ol>
</li>
</ol>
</li>
</ul>
<ul>
<li>Lookout is not responsible for performing any support and maintenance Services if Customer fails to maintain Products as required by Lookout. Customer is responsible to notify Lookout immediately of any malfunction, breakdown or failure of any Product, and Lookout will use commercially reasonable efforts to respond to such reports. Lookout provides no representation, warranty, or guarantee that it will be able to remedy any malfunction, breakdown or failure of any Product in any particular timeframe, if at all.&nbsp;</li>
</ul>
<ol>
<li>Lookout does not provide any onsite support. In the event Lookout is unable to connect to any Hardware to provide the above Services and Customer must contact Lookout to receive a Services authorization for the Hardware. In the event Lookout approves the authorization, the Hardware must be shipped to Lookout at Customer&rsquo;s cost with the authorization number indicated on the shipment from Customer. The Hardware must be packaged in accordance with Lookout&rsquo;s instructions and shipped to Lookout at the address indicated by Lookout in its Services authorization. Lookout has no obligation to approve any Services authorization.</li>
</ol>
<ol>
<li><u>Prerequisites</u>. Lookout&rsquo;s ability to perform and complete Services is dependent upon Customer&rsquo;s compliance with all necessary requirements and prerequisites for the Location, the Location environment and any telecommunications and information technology systems as required for the performance of the Services as set forth in this Agreement, including without limitation Internet connectivity. Customer must (i) cooperate with Lookout in all matters relating to the Services; (ii) respond promptly to any Lookout request to provide direction, information, approvals, authorizations, or decisions that are reasonably necessary for Lookout to perform Services in accordance with the requirements of this Agreement; (iii) provide such customer materials or information as Lookout may request to carry out the Services in a timely manner and ensure that such customer materials or information are complete and accurate in all material respects; and (iv) obtain and maintain all necessary licenses and consents and comply with all applicable laws in relation to the Products before the date on which the Products are installed and in use.</li>
<li><u>Delays</u>. If Lookout's performance of its obligations under this Agreement is prevented or delayed by any act or omission of Customer or its agents, subcontractors, consultants, or employees, Lookout will not be deemed in breach of its obligations under this Agreement or otherwise liable for any costs, charges, or losses sustained or incurred by Customer, in each case, to the extent arising directly or indirectly from such prevention or delay.</li>
<li><u>Service Exclusions</u>. Lookout&rsquo;s Services do not include consumable Products or other supplies, accessories, or any items normally consumed during the use of Products and Software. Services also expressly exclude any onsite service calls, whether resulting from Customer&rsquo;s failure to maintain Internet connectivity to the Hardware, implement or install Updates provided by Lookout, failure to perform operator-level preventive maintenance, failure to follow Lookout installation instructions, Location-related problems, operator error, improper environmental conditions, electrical power fluctuations, RF noise interference, hardware or software added to a Product, or any other cause. Services required due to abuse or misuse of equipment, or alterations, modifications, or repairs to equipment not performed or provided by Lookout, negligence of Customer or any third party, or Customer&rsquo;s denial of access to a Product, or Customer networks or systems, may be subject to additional fees to be invoiced by Lookout. Lookout does not provide services for furnishing materials or supplies for any construction, cabling or similar work at the Customer&rsquo;s Location.</li>
</ol>
<ul>
<li><strong>Ongoing Customer Responsibilities</strong>.
<ol>
<li>Customer must ensure that it maintains an active cell phone number with Lookout to receive Notifications as contemplated above. Customer understands and agrees that the Hardware takes a photographic image of individuals that pass the Hardware. Customer must implement its own policies and procedures to ensure that Customer&rsquo;s personnel do not misuse any information received through Notifications or otherwise through the Products. Customer must ensure that it has sufficient protections in place to meet applicable data privacy and security laws and regulations with regard to any personal information Customer collects or receives via the Products, or that Customer otherwise elects to input into the Software, including without limitation compliance with all data privacy principles of transparency, use limitations, security, and data minimization.</li>
<li>Customer must ensure that all administrators and users are capable of performing all necessary operational and administrative Product functions associated with using and administering the Products on a daily basis and in accordance with applicable Documentation.</li>
<li>Customer understands and agrees that the Products are intended to be used solely within the Location, on networks and mobile devices that are not controlled or accessed by Lookout. Customer is responsible for ensuring that the systems and networks are functioning as required in order for the Products to perform in accordance with the Documentation.&nbsp;</li>
<li>Customer is solely responsible for the calibration and settings used to operate the Products. In no way do the Products make any judgment as to the health or contagion risk of any individual. Lookout does not require the use of or access to any protected health information or other personal information and Customer may not provide Lookout with access to or use of the same unless agreed in writing by Lookout.&nbsp;</li>
</ol>
</li>
<li>
<ol>
<li><u>Price</u>. Customer will purchase the Products from Lookout at the prices (the "Price(s)") set forth in Exhibit A, or if no Prices are identified in Exhibit A for the applicable Product, then the Prices will set pursuant to Lookout's then-current published pricing list. Invoices for Products are due to Lookout upon receipt, unless otherwise agreed in writing by the Parties. Customer will make all payments hereunder by credit or other payment card (unless Lookout instructs Customer otherwise) and in US dollars.</li>
<li><u>Taxes</u>. Customer is responsible for all applicable sales, transfer, excise, and/or use taxes payable to any federal, state or local taxing authority resulting from Customer purchase of Products hereunder, excluding taxes related to Lookout's income or relating to payment of compensation of Lookout&rsquo;s employees (&ldquo;<strong>Taxes</strong>&rdquo;). Lookout may invoice such Taxes to Customer, which invoice is due and payable as set forth above, unless Customer provides Lookout with a tax exemption certificate or other suitable evidence of tax exemption status.</li>
<li><u>Late Payment</u>. For all amounts due to Lookout, Lookout may charge Customer interest on all late payments invoiced by Lookout, but not paid within thirty (30) days of the invoice date, at the lesser of the rate of 1.5% per month or the highest rate permissible under applicable law, calculated daily and compounded monthly. Customer will reimburse Lookout for all costs incurred in collecting any late payments due to Lookout, including, without limitation, attorneys' fees. In addition to all other remedies available under these Agreement or at law (which Lookout does not waive by the exercise of any rights hereunder), Lookout is further entitled to suspend the delivery of any Products and performance of any Services if Customer fails to pay any amounts when due hereunder.</li>
<li><u>No Right to Set-Off</u>. Customer may not withhold payment of any amounts due and payable by reason of any set-off of any claim or dispute with Lookout, whether relating to Lookout's breach, bankruptcy or otherwise.</li>
</ol>
</li>
<li><strong>Intellectual Property</strong>.
<ol>
<li><u>Lookout Property.</u> Customer acknowledges and agrees that, as between Lookout and Customer, Lookout has and will retain all right, title, interest, and ownership in and to the Products, its technology, processes, methodologies, and any other proprietary materials of Lookout or its licensors, including without limitation its source code together with any updates, modifications, revisions, changes, copies, partial copies, translations, compilations, and derivative work and any copies or updates of the Software and its source code, together with all patent, copyright, trade secrets, trademarks and all other intellectual property rights (collectively &ldquo;<strong>Lookout Property</strong>&rdquo;). Neither Customer nor its employees, personnel, agents, or assigns have any property interest in Lookout Property other than the limited ability to use the same for their intended purpose in accordance with the Documentation as contemplated by this Agreement.</li>
<li>Customer may not make any modifications, translations, or other derivatives of any Products or Documentation. Notwithstanding the foregoing, in the event Customer creates or has created any such modifications, translations or other derivatives, Customer expressly assigns to Lookout (without further compensation or consideration) all right, title and interest in and to the same, together with all patent, copyright, trade secret, trademark and other intellectual property rights. Lookout will solely have the right to obtain and to hold in its own name, patents, copyright registrations and trademark registrations, and such other protection as may be appropriate to the subject matter and any extensions and renewals thereof.</li>
<li>To the extent Customer provides any feedback, suggestions, or comments related to the Products, Lookout has the right to retain and use any such feedback, suggestions and comments in current or future products or services, without Customer's approval or further compensation. Any developments, improvements, and modifications that may result from such Customer feedback are and remain the sole and exclusive property of Lookout as Lookout Property defined above.&nbsp;</li>
</ol>
</li>
<li><strong>Confidentiality</strong>.
<ol>
<li>&ldquo;Confidential Information&rdquo; is any confidential or proprietary information of a Party or its affiliates disclosed or made available to the other Party prior to or during the term of the Agreement that such Party maintains as confidential, whether or not expressly marked as &ldquo;confidential&rdquo; or &ldquo;proprietary&rdquo; or other similar marking, that a Party should reasonably know to be confidential by the nature of the information or context of the disclosure. A Party&rsquo;s Confidential Information includes a Party's financial, business, operational, technical and product information, a Party's intellectual property, and any information marked as confidential, provided the same is kept and maintained as confidential by the disclosing Party. Lookout&rsquo;s Confidential Information expressly includes without limitation Lookout Property.</li>
<li>Each Party will hold the other Party&rsquo;s Confidential Information in strict confidence, only disclosing it to those of its employees with a need to know to perform or exercise licenses as provided under this Agreement. Each Party will not disclose the other Party&rsquo;s Confidential Information to any third parties without the disclosing Party&rsquo;s prior written consent. If such consent is granted, disclosure may not occur until such third party has signed a confidentiality agreement with terms at least as protective as set forth herein. The only purpose for which the disclosing Party&rsquo;s Confidential Information may be used by the receiving Party is for the receiving Party to perform its obligations or exercise its rights under this Agreement. Notwithstanding the foregoing, Lookout may disclose Customer&rsquo;s Confidential Information to those of Lookout&rsquo;s contractors, vendors, employees, and suppliers engaged by Lookout to perform or facilitate Services.&nbsp;</li>
<li>If compelled by applicable law or court order to disclose the other Party&rsquo;s Confidential Information, then, to the extent legally permitted, the receiving Party will: (a) provide the disclosing Party prior written notice with sufficient time to challenge the request or seek a protective order, and (b) only disclose the minimum amount of the disclosing Party&rsquo;s Confidential Information necessary to comply with the applicable law or court order. Each Party will promptly provide written notice to the other of any known or suspected breach of the other Party's Confidential Information.&nbsp;</li>
<li>Upon termination or expiration of this Agreement, the receiving Party will destroy or return to the disclosing Party all of the disclosing Party's Confidential Information, including all copies, whichever is requested, and certify in writing that the receiving Party has complied with the requirements of this provision. Violation of this Section may cause irreparable harm to the disclosing Party and as such, the disclosing Party is entitled to seek an injunction for any breach or threatened breach of this Section without the need to post bond or other security, or other similar requirement.</li>
</ol>
</li>
<li><strong>Data<strong> Privacy and Security</strong></strong>
<ol>
<li><u>Data Privacy and Security Requirements</u>. The Product's ability to access and store data is dependent on the settings and configurations the Customer chooses in accordance with the applicable Documentation. Customer will implement and will maintain an effective written information security program to protect data received through Notifications and Lookout&rsquo;s Confidential Information, which includes administrative, technical, and physical safeguards to help ensure the security and confidentiality of Notifications and Confidential Information.&nbsp;</li>
<li>Security Breach. If either Party is in breach of this Section or otherwise suspects a breach of this Section, it will immediately advise the other of the breach and will undertake reasonable measures to prevent a continuing or recurring breach and to mitigate any damages arising from or related to the breach. Each Party agrees to cooperate fully with the other in the event further investigation of any such breach may be required.</li>
<li>Performance Data. Lookout will have the right to connect to the Hardware remotely and without notice in order to collect, compile, or create any data and information related to or derived from Customer's use of Products including without limitation, to compile or create statistical and performance information related to the provision and operation of the Products ("<strong>Performance Data</strong>") for Lookout's business, product development, and training purposes. Performance Data will be de-identified and will not contain personal information or Confidential Information of the Customer. All Performance Data may be used by Lookout as contemplated in this Agreement for ongoing Product improvement, modifications and other development.&nbsp;</li>
</ol>
</li>
<li><strong>Representations and Limited Warranty</strong>.
<ol>
<li>Mutual Representations. Each Party represents to the other that: (i) it is duly incorporated, validly existing and in good standing under the laws of the jurisdiction of its incorporation and has the power and authority to enter into and perform its obligations under this Agreement; (ii) the person executing and delivering this Agreement on behalf of such Party is duly authorized to make such execution and delivery; (iii) this Agreement constitutes a valid obligation, binding upon and enforceable against each such Party in accordance with its terms; and (iv) execution and delivery of this Agreement and the performance of each such Party&rsquo;s obligations do not breach any contract between such Party and any third party.</li>
<li>Customer Warranty. Customer warrants and covenants that its implementation and use of the Products, including the collection, display and other use of any data collected via the Products, will comply with all applicable federal and state laws, rules, and regulations, including without limitation all data privacy and security laws, rules and regulations. Customer further represents and warrants that its use and implementation of the Products will comply with all Documentation, provided in the event any Documentation conflicts with applicable laws, Customer must comply with applicable law.&nbsp;</li>
<li><strong>WARRANTY</strong><strong><u> DISCLAIMER</u></strong><strong>. GIVEN THE PRODUCTS ARE STILL IN DEVELOPMENT STAGE, THE PRODUCTS ARE PROVIDED "AS IS". LOOKOUT MAKES NO WARRANTY WHATSOEVER WITH RESPECT TO LOOKOUT PROPERTY OR THE PRODUCTS, AND EXPRESSLY DISCLAIMS ALL WARRANTIES EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT, AND ANY WARRANTIES IMPLIED BY A COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE. PRODUCTS SERVICES ARE NOT FAULT TOLERANT AND ARE NOT DESIGNED, MANUFACTURED, OR INTENDED FOR USE OR RESALE AS CONTROL EQUIPMENT IN HAZARDOUS ENVIRONMENTS REQUIRING FAIL-SAFE PERFORMANCE OR IN ANY ENVIRONMENT IN WHICH FAILURE COULD LEAD DIRECTLY TO DEATH, PERSONAL INJURY, OR PHYSICAL OR ENVIRONMENTAL DAMAGE. LOOKOUT MAKES NO WARRANTY THAT THE PRODUCTS OR ANY COMPONENT THEREOF WILL BE UNINTERRUPTED OR ERROR-FREE. LOOKOUT DOES NOT WARRANT THAT THE PRODUCTS WILL MEET CUSTOMER'S BUSINESS NEEDS. </strong></li>
</ol>
</li>
<li><strong>Limitation of Liability</strong>.
<ol>
<li><strong>IN NO EVENT WILL LOOKOUT BE LIABLE TO CUSTOMER OR ANY THIRD PARTY FOR ANY LOSS OF USE, LOSS OF REVENUE, LOSS OF GOODWILL, LOSS OF PROFIT OR LOSS OF DATA, OR FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES WHETHER ARISING OUT OF THIS AGREEMENT OR USE OR RECEIPT OF ANY LOOKOUT PROPERTY, OR PRODUCTS, WHETHER SUCH DAMAGES ARISE FROM A BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER OR NOT LOOKOUT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</strong></li>
<li><strong>IN NO EVENT WILL LOOKOUT'S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT OR ANY OF LOOKOUT PROPERTY, OR PRODUCTS, WHETHER ARISING OUT OF OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EXCEED THE TOTAL OF THE AMOUNTS PAID TO LOOKOUT FOR THE APPLICABLE PRODUCT OR SERVICE</strong><strong> IN THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE CLAIM.</strong></li>
<li><strong>THE FOREGOING EXCLUSIONS AND LIMITATIONS ARE AN ESSENTIAL ELEMENT OF THE AGREEMENT AND APPLY, AND ARE INTENDED TO APPLY, EVEN IF ANY SUCH REMEDY SHOULD FAIL OF ITS ESSENTIAL PURPOSE TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.</strong></li>
</ol>
</li>
<li><strong>Indemnification</strong>.
<ol>
<li>Customer will indemnify, defend, and hold harmless Lookout and its employees, contractors, officers, directors, agents and representatives from and against any fees, fines, costs, damages, liabilities, and expenses (including reasonable out of pocket attorneys&rsquo; fees and costs) incurred as a result of any third party or government claim, allegation, lawsuit, demand or investigation based upon or arising from: (i) Customer&rsquo;s use or implementation of Lookout Property or Products, including at a Location; (ii) violation of Customer's data privacy and security obligations or the disclosure of any personal or protected health data to Lookout or any third party; (iii) Customer's breach of this Agreement; (iv) Customer's violation of applicable laws or regulations; or (v) Customer's or its personnel's negligence or willful misconduct.</li>
<li>Lookout will indemnify, defend, and hold harmless Customer and its employees, contractors, officers, directors, agents and representatives from and against any fees, fines, costs, damages, liabilities, and expenses (including reasonable out of pocket attorneys&rsquo; fees and costs) incurred as a result of any third party claim alleging that the Products infringe a registered United States patent or trademark.</li>
<li>The Party seeking indemnification pursuant to the foregoing Section ("<strong>Indemnified Party</strong>") will provide written notice of a claim to Party obligated to indemnify ("<strong>Indemnifying Party</strong>"), and the requisite information, reasonable assistance and authority for Indemnifying Party to control the defense. Failure to give prompt notice will not relieve Indemnifying Party of its obligations to indemnify and hold harmless the Indemnified Party, except and only to the extent that Indemnifying Party is actually prejudiced by such delay. Notwithstanding, Indemnifying Party may not settle any claim that finds fault with, or requires specific performance of, any Indemnified Party without the Indemnified Party&rsquo;s prior written consent, which consent may not be unreasonably withheld, conditioned or delayed.</li>
</ol>
</li>
<li><strong>Term</strong><strong>; Termination</strong>.
<ol>
<li>This Agreement will commence upon the Effective Date and will continue for the duration of the subscription term listed in Exhibit A. The term will autorenew for subsequent terms of equal length, unless either Party notifies the other of an intent not to renew at least forty-five (45) days in advance of the expiration of the then-current subscription period or unless otherwise terminated earlier in accordance with this Section. This Agreement may be extended for additional consecutive annual periods as agreed upon in writing by the Parties.&nbsp;</li>
<li>A Party may terminate this Agreement upon the other Party&rsquo;s failure to cure a material breach of this Agreement, if the breaching Party fails to cure such material breach within thirty (30) days of written notice of the breach sent by the non-breaching Party reasonably identifying such breach.</li>
<li>Lookout may terminate this Agreement with advanced written notice to Customer in the event Customer files a petition for bankruptcy or is adjudicated bankrupt, or a petition of bankruptcy is filed against Customer and not dismissed within thirty (30) days.</li>
<li>Once Lookout has accepted Customer&rsquo;s Purchase Order, a Purchase Order cannot be cancelled, without Lookout's express written consent. In the event Lookout grants written consent, Customer will be responsible for all shipping, material and labor costs incurred prior to cancellation. Lookout may, at its sole discretion, credit Customer&rsquo;s account for returned Products minus return shipping costs and applicable restocking fees.&nbsp;</li>
<li>Upon termination of this Agreement, Lookout will cease the performance of all Services contemplated by this Agreement, and Lookout will issue a final invoice for any fees incurred and due and payable to Lookout. The Hardware will cease to function upon termination under this Agreement unless otherwise agreed upon by the Parties in writing.</li>
<li>All provisions of this Agreement that by their nature are intended to extend beyond the termination or expiration of this Agreement for any reason survive the termination or expiration of this Agreement.</li>
</ol>
</li>
<li>Force Majeure. Lookout will not be liable or responsible to Customer, nor be deemed to have defaulted or breached this Agreement, for any failure or delay in fulfilling or performing any term of this Agreement when and to the extent such failure or delay is caused by or results from acts or circumstances beyond the reasonable control of Lookout including, without limitation, acts of God, flood, fire, earthquake, explosion, governmental actions, war, invasion, or hostilities (whether war is declared or not), terrorist threats or acts, riot, or other civil unrest, national emergency, revolution, insurrection, pandemics or epidemic, quarantines, lockouts, strikes or other labor disputes (whether or not relating to either Party's workforce), or restraints or delays affecting carriers or inability or delay in obtaining supplies of adequate or suitable materials, materials or telecommunication breakdown or power outage.</li>
<li>Assignment. Customer may not assign any of its rights or delegate any of its obligations under this Agreement without the prior written consent of Lookout. Any purported assignment or delegation in violation of this Section is null and void. No assignment or delegation relieves Customer of any of its obligations under this Agreement.</li>
<li>No Third-party Beneficiaries. This Agreement is for the sole benefit of the Parties hereto and their respective successors and permitted assigns and nothing herein, express or implied, is intended to or will confer upon any other person or entity any legal or equitable right, benefit, or remedy of any nature whatsoever under or by reason of this Agreement.</li>
<li><u>Claims Limitations.</u> No lawsuit or other action may be brought by Customer arising in any way out of this Agreement, after one (1) year from the date on which the cause of action arose regardless of the nature of the claim or form of action, whether in contract, tort (including negligence) or</li>
<li>Governing Law and Venue. All matters arising out of or relating to this Agreement are governed by and construed in accordance with the laws of the State of Arizona without giving effect to any choice or conflict of law provision or rule. The state and federal courts with jurisdiction in Maricopa County, Arizona have exclusive jurisdiction over any disputes arising under this Agreement and the Parties agree to the personal jurisdiction of such courts, waiving any argument of inconvenience forum.</li>
<li>Notices. All notices or communications required under this Agreement are to be sent in writing: (a) by facsimile transmission (with proof of transmission); (b) by email transmission (with return receipt evidence); or (c) by overnight mail courier service. Any notice sent by electronic transmission will be deemed delivered upon the date of successful transmission. Any notice sent by overnight delivery will be deemed delivered upon receipt. If the notice is sent on a weekend or holiday, the notice will be deemed received on the following business date.</li>
<li>If any provision of this Agreement is invalid or unenforceable under any statute or rule or law, the provision is to that extent to be deemed omitted, and the remaining provisions will not be affected in any way.</li>
<li>Waiver. No term, condition, or provision of this Agreement will be deemed waived and no breach excused unless such waiver or consent will be in writing and signed by a duly authorized representative of Lookout.</li>
<li>This Agreement may be executed in one or more counterparts, each of which will be an original and together all counterparts are a single instrument.</li>
<li>Independent Contractor. This Agreement may not be construed as creating any agency, partnership, joint venture, or other similar legal relationship between the Parties; nor will either Party hold itself out as an agent, partner, or joint venture Party of the other Party. Both Parties will be, and will act as, independent contractors. Neither Party has the authority to create any obligation or make any commitments on the other Party&rsquo;s behalf.</li>
<li>Amendment and Modification. This Agreement may only be amended or modified in a writing stating specifically that it amends this Agreement and is signed by an authorized representative of each Party.</li>
<li>The Agreement has been carefully reviewed and fully negotiated by the Parties. It will therefore not be construed against either Party on the grounds that all or any Section of the Agreement was drafted by any particular Party.</li>
<li>Entire Agreement. This Agreement, along with any attached exhibits and schedules, comprise the entire agreement between the Parties with respect to the purchase and provision of Products and supersede all prior or contemporaneous understandings, agreements, negotiations, representations and warranties, and communications, both written and oral, with respect to such Products and Services. This Agreement prevails over any of Customer's general terms and conditions of purchase regardless whether or when Customer has submitted its Purchase Order or such terms. Fulfillment of Customer's Purchase Order does not constitute acceptance of any of Customer terms and conditions and does not serve to modify or amend this Agreement.</li>
</ul>
<p>The Customer hereto has executed this Agreement by checking the box below on the form.</p>
      </div>
    </div>
  );
}

export default EULA;
