/*eslint-disable*/
import React from "react";

import {ReactComponent as Device} from 'assets/img/device.svg';

import {ReactComponent as SMS} from 'assets/img/sms.svg';

import {ReactComponent as Scan} from 'assets/img/scan.svg';

import './Privacy.scss';

function Privacy() {
  React.useEffect(() => {});

  return (
    <section
      className="section-grey section-top-border section-bottom-border"
      id="features"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center padding-bottom-10">
            <h2 className="section-title">Privacy</h2>

            <p className="section-subtitle">
              Helm Detector offers numerous options to address the complex <strong>data security</strong> and <strong>privacy</strong>
              challenges of today. The device can be directly integrated into any network of choice, leaving all of the data control 
              and policy up to the client. If full white glove service is preferred, Helm Detector can do that too.
            </p>
          </div>
        </div>

      </div>
    </section>
  );
}

export default Privacy;
