import React from "react";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
import Footers from "components/Footers"

// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import PaymentForm from "components/PaymentForm";
import PaymentFormServiceBot from "components/PaymentFormServiceBot";





// sections for this page

function Payments() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <div>
      <MainNavbar />
      <PaymentFormServiceBot />
      <Footers />
    </div>
  );
}

export default Payments;
