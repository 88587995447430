/*eslint-disable*/
import React from "react";

function WhatWeOffer() {
  React.useEffect(() => {});

  return (
    <section className="section-white section-top-border">
      <h2 className="section-title text-center">What We Offer</h2>
      <br />
      <div className="container">
        <div className="row">
          <div
            className="col-md-6 responsive-bottom-margins wow slideInLeft"
            data-wow-delay="0.25s"
            style={{
              visibility: "visible",
              animationDelay: "0.25s",
              animationName: "slideInLeft",
              textAlign: "center",
              margin: "0 auto",
            }}
          >
          <div>
            <h4 className="mb-3">Temperature Monitoring</h4>
            <h6>&nbsp;</h6>
            <img
              alt="product shot"
              height="400px"
              src={require("assets/img/temp-detection.jpg")}
            ></img>
            <p className="mt-3">
              Monitor temperature of all people entering your business.
            </p>
            </div>
          </div>
          <div
            className="col-md-6 responsive-bottom-margins wow slideInLeft"
            data-wow-delay="0.25s"
            style={{
              visibility: "visible",
              animationDelay: "0.25s",
              animationName: "slideInLeft",
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <div>
            <h4 className="mb-3">Face Mask Detection</h4>
            <h6>Coming Soon</h6>
            

              <img
                alt="product shot"
                height="400px"
                src={require("assets/img/mask-detection.jpg")}
              ></img>
              <p className="mt-3">
                Stop the spread and meet CDC guidelines by detecting staff and customers without masks.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhatWeOffer;
